import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  CoinDTO,
  CoinRequestPayloadSchema,
  LocalizationDTO, LocalizationRequestPayloadSchema,
} from 'views/types';

const baseUrl = '/api/v1/';
// const baseUrl = 'https://payment.cryptopay-dev.space-app.io/api/v1/';
const tags = ['Global'];

export const globalApi = createApi({
  reducerPath: 'globalApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: tags,
  endpoints: (builder) => ({
    getCryptoCoinsByFiatCurrency: builder.query<CoinDTO[], CoinRequestPayloadSchema>({
      query: ({ currency, authToken }) => ({
        url: `crypto/coins?fiatCurrency=${currency}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
    }),

    getLocalization: builder.query<LocalizationDTO, LocalizationRequestPayloadSchema>({
      query: ({ languageIso2, authToken }) => ({
        url: `resources/localizations/${languageIso2}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetCryptoCoinsByFiatCurrencyQuery,
  useGetLocalizationQuery,
} = globalApi;
