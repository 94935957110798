import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  DepositQuoteRequestPayloadSchema,
  PaymentIntentDTO,
  PaymentIntentPayloadSchema,
  QuoteDTO,
  initialQueryParamsSchema,
} from 'views/types';

const baseUrl = '/api/v1/';
// const baseUrl = 'https://payment.cryptopay-dev.space-app.io/api/v1/';
const tags = ['Deposit'];

export const depositApi = createApi({
  reducerPath: 'depositApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: tags,
  endpoints: (builder) => ({
    postPaymentIntent: builder.mutation<PaymentIntentDTO,
      { paymentIntentData: PaymentIntentPayloadSchema } & { authToken: string }>({
        invalidatesTags: tags,
        query: ({ paymentIntentData, authToken }) => ({
          url: 'payment-intents',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: paymentIntentData,
        }),
      }),
    getPaymentGateway: builder.query<initialQueryParamsSchema, { authToken: string }>({
      query: ({ authToken }) => ({
        url: 'payment-intents',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
    }),
    postPaymentIntentQuote: builder.mutation<QuoteDTO,
      { paymentIntentQuoteData: DepositQuoteRequestPayloadSchema } & { authToken: string }
    >({
      invalidatesTags: tags,
      query: ({ paymentIntentQuoteData, authToken }) => ({
        url: 'payment-intents/quote-request',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: paymentIntentQuoteData,
      }),
    }),
  }),
});

export const {
  usePostPaymentIntentMutation,
  usePostPaymentIntentQuoteMutation,
  useGetPaymentGatewayQuery,
} = depositApi;
